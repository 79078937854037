
@use 'helpers/general' as *;
@use 'helpers/colors';

.HealthTipsSection{
    $top-shadow-padding: 5rem;

    position: relative;
    margin-top: -$top-shadow-padding;
    @include setProps(margin-left margin-right, calc(var(--screen-side-insets) * -1));
    
    > .left-gradient, .right-gradient{
        z-index: 10;
        position: absolute;
        width: 5rem;
        pointer-events: none;
    }
    > .left-gradient{
        @include setProps(left top bottom, 0);
        background: linear-gradient(to right, white, rgba(white, 0));
    }
    > .right-gradient{
        @include setProps(right top bottom, 0);
        background: linear-gradient(to left, white, rgba(white, 0));
    }
    
    
    > .content-holder{
        padding-top: $top-shadow-padding;
        overflow-x: scroll;
        padding-bottom: 3rem;
        @include setCustomScrollBarStyle();
    }

    > .content-holder > .content{
        
        $cell-height: 23rem;
        
        @include setProps(padding-left padding-right, var(--screen-side-insets));
        display: inline-grid;
        grid-auto-flow: column;
        gap: 2rem;

        @include respondToSmallMaxSize{
            gap: 1.5rem;
        }

        > .title-cell{
            
            display: grid;
            gap: 1rem;
            align-content: center;
            height: $cell-height;
            width: 15rem;
            margin-right: 2rem;
            margin-left: 2rem;

            @include respondToSmallMaxSize{
                margin-left: 1rem;
                margin-right: 1rem;
                width: 13rem;
            }

            > .title{
                font-weight: bold;
                font-size: 2rem;

                @include respondToSmallMaxSize{
                    font-size: 1.7rem;
                }
            }

            > .description{
                color: gray(0.6);
                font-size: 1rem;
            }

            > .see-all-button{
                justify-self: start;
                padding: 1rem;
                @include setProps(padding-top padding-bottom, 0.5rem);
                border: 0.15rem solid colors.$theme-green;
                color: colors.$theme-green;
                font-weight: 500;
                border-radius: 0.7rem;
                @include bounceOnHover;
            }

        }

        > .HealthTipCell{
            $cell-width: 15rem;
            
            display: grid;
            grid-template-rows: auto 1fr;

            height: $cell-height;
            width: $cell-width;
            border-radius: 1rem;
            overflow: hidden;
            z-index: 1;
            box-shadow: 0 0 3rem rgba(black, 0.15);
            transition-property: box-shadow, transform;
            transition-duration: 0.25s;
            transition-timing-function: ease-out;

            @media(hover: hover){
                &:hover{
                    transform: scale(1.05);
                    box-shadow: 0 0 3rem rgba(black, 0.3);
                }
            }

            > .thumbnail-box{

                height: ((9/16) * $cell-width) - 0.2rem;
                background-color: colors.$gray-background;
                position: relative;

                > img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
                
                > svg{
                    fill: gray(0.85);
                    position: absolute;
                    left: 50%;top: 50%;
                    transform: translate(-50%, -50%);
                }
                
                > .text-icon-svg{
                    @include setProps(width height, 4rem);
                }

                > .audio-icon-svg{
                    @include setProps(width height, 3.5rem);
                }
            }

            .text-section{
                display: grid;
                align-content: space-between;
                padding: 1.3rem;

                > .date{
                    color: colors.$theme-green;
                    font-weight: 600;
                    font-size: 0.9rem;
                }

                > .title{
                    font-weight: bold;
                    @include setMaximumLines(2);
                }

                > .description{
                    color: gray(0.7);
                    @include setMaximumLines(3);
                    font-size: 0.9rem;
                }

                > .read-more-button{

                    display: grid;
                    align-items: center;
                    grid-auto-flow: column;
                    justify-self: start;
                    gap: 0.5rem;

                    > .text{
                        text-transform: uppercase;
                        font-weight: 500;
                        color: colors.$theme-green;
                        font-size: 0.9rem;
                    }

                    > svg{
                        fill: colors.$theme-green;
                        display: block;
                        @include setProps(width height, 1.2rem);
                    }
                    
                }
            }
        }
    }
}

