

@use 'colors';
@use 'sass:color';

$responsive-medium-max-size: 63.94rem;
$response-small-max-size: 48rem;

@mixin respondToMediumMaxSize {
    @media (max-width: $responsive-medium-max-size){
        @content;
    }
}

@mixin respondToSmallMaxSize{
    @media (max-width: $response-small-max-size){
        @content;
    }
}


/// returns a gray color according to the value given. EXPECTS A DECIMAL!!!
@function gray($levelOfGrayness){
    $v: $levelOfGrayness * 255;
    @return rgb($v, $v, $v);
}


@mixin setProps($properties, $value){
    @each $property in $properties{
        #{$property}: $value;
    }
}



@mixin bounceOnHover($bounce-scale: 1.1) {
    
    @media (hover: hover){
        transition-duration: 0.2s;
        transition-property: transform;
        &:hover{
            transform: scale($bounce-scale);
            @content;
        }
    }
    
    cursor: pointer;
}

@mixin roundedBGOnHoverAnimConfig($properties){
    transition: {
        duration: 0.2s;
        property: $properties;
    }    
}

@mixin roundedBGOnHover($rounded-BG-color, $hover-text-color: null){
    
    @include roundedBGOnHoverAnimConfig(color);

    position: relative;
    cursor: pointer;

    &::before{
        content: '';
        position: absolute;
        background-color: $rounded-BG-color;
        @include setProps(left right, 0);
        height: 100%;
        top: 50%;
        transform: translateY(-50%);
        border-radius: 1000rem;
        opacity: 0;
        @include roundedBGOnHoverAnimConfig(opacity);
    }

    svg{
        @include roundedBGOnHoverAnimConfig(fill);
    }

    @media(hover: hover){
        &:hover{
            color: $hover-text-color;
            svg{
                fill: $hover-text-color;
            }
        }
        &:hover::before{
            opacity: 1;
        }
    }

}

@mixin greenBGOnHover(){
    @include roundedBGOnHover(rgba(colors.$theme-green, 0.2), colors.$theme-green); 
}



@mixin setCustomScrollBarStyle($color: gray(0.9)){
    $scroll-bar-size: 0.65rem;
    &::-webkit-scrollbar {
        @include setProps(width height, $scroll-bar-size);
    }
    &::-webkit-scrollbar-thumb {
        border-radius: $scroll-bar-size / 2;
        background-color: $color;
    }
}



@mixin setMaximumLines($max-lines){
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-line-clamp: $max-lines;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

@mixin dimOnHover($setPosition: true){
    cursor: pointer;
    @if $setPosition{
        position: relative;
    }
    overflow: hidden;   
    
    &::after{
        content: '';
        position: absolute;
        @include setProps(left right top bottom, 0);
        background-color: rgba(black, 0);
        transition-property: background-color;
        transition-duration: 0.2s;
        z-index: 100;
    }

    @media(hover: hover){
        &:hover::after{
            background-color: rgba(black, 0.1);
        }
    }
}

@mixin opaqueOnHover($setPosition: true){
    cursor: pointer;
    @if $setPosition{
        position: relative;
    }
    
    opacity: 1;
    transition-property: opacity;
    transition-duration: 0.2s;

    @media(hover: hover){
        &:hover{
            opacity: 0.5;
        }
    }
}


@mixin break-long-words{    
    // I got this from here: https://stackoverflow.com/questions/36418352/css-word-wrap-break-word-wont-work
    // this works but it breaks words at all characters, even when it isn't necessary to break them. Find a better way!!!!
    // overflow-wrap: break-word;
    // word-wrap: break-word;
    // -ms-word-break: break-all;
    // word-break: break-all;
    // word-break: break-word;
    // -ms-hyphens: auto;
    // -moz-hyphens: auto;
    // -webkit-hyphens: auto;
    // hyphens: auto;



    word-break: break-word;
    word-wrap: break-word; // for microsoft edge on windows

}






/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
    @if type-of($number) == 'number' and not unitless($number) {
      @return $number / ($number * 0 + 1);
    }
  
    @return $number;
}