
@use 'helpers/colors';
@use 'helpers/general' as *;

.Consults{

    position: relative;
    @include setProps(padding-top padding-bottom, 4rem);

    @include respondToSmallMaxSize{
        @include setProps(padding-top padding-bottom, 3rem);
    }

    .TitleSubtitleContainer{

        display: grid;
        gap: 1rem;

        > .line{
            height: 0.4rem;
            background-color: colors.$theme-green;
            border-radius: 1000000000rem;
            width: 4rem;
        }
        
        > .title{
            font-weight: bold;
            font-size: 1.6rem;
        }
        
        > .description{
            font-size: 1rem;
            color: gray(0.6);
        }

        @include respondToSmallMaxSize{
            text-align: center;
            justify-items: center;
            > .title{
                font-size: 1.3rem;
                font-weight: bold;
            }
        }
    }

    > .background-view{
        z-index: -1;
        width: 100vw;
        position: absolute;
        height: 100%;
        top: 0; left: 50%;
        transform: translateX(-50%);
        background-color: colors.$gray-background;
    }

    > .content{

        max-width: 60rem;
        margin-top: 4rem;
        @include setProps(margin-left margin-right, auto);


        @include respondToSmallMaxSize{
            max-width: 30rem;
        }

        > .corporate-wellness-container{
    
            display: grid;
            grid-template-columns: 1fr 1.2fr;
            align-items: center;
            gap: 3.5rem;

            
            > .left-section{
    
                margin-right: -12%;
                margin-bottom: -6%;
    
                position: relative;
                display: grid;
                gap: 1rem;
                grid-template-columns: 1fr 1fr;
                grid-template-rows: auto auto;
    
                
                
                > .ImageContainer{
                    > div {
                        padding-top: 68%;
                        position: relative;
                        > div{
                            position: absolute;
                            @include setProps(top left bottom right, 0);
                            box-shadow: 0 0 1rem rgba(black, 0.3);
                            overflow: hidden;
                            position: absolute;
                            border-radius: 0.5rem;
                            overflow: hidden;
        
                            > img{
                                object-fit: cover;
                                @include setProps(left top, 0);
                                @include setProps(width height, 100%);
                                
                                position: absolute;
                                @include setProps(left right bottom top, 0);
                            }
                        }
                    }
                }
    
                > div:nth-child(1){
    
                }
                > div:nth-child(2) > div > div{
                    @include setProps(right top, 23%);
                }
                > div:nth-child(3) > div > div{
                    @include setProps(left bottom, 15%);
                }
                > div:nth-child(4) > div > div{
                    @include setProps(right bottom, 35%);
                }
            }
    
            @include respondToSmallMaxSize{

                grid-template-columns: auto;
                gap: 2.5rem;
    
                > .left-section{
                    max-width: 25rem;
                    justify-self: center;
                    width: 100%;
                    grid-row: 2;
                    gap: 0.65rem
                }
            }
    
        }
    
        > .personal-wellness-container{
            margin-top: 6rem;
            @include setProps(margin-left margin-right, auto);
            display: grid;
            grid-auto-flow: column;
            justify-content: space-between;
            align-items: center;
            gap: 2rem;
            align-items: center;
    
            > .TitleSubtitleContainer{
                max-width: 22rem;
            }
    
            > .column{
                display: grid;
                gap: 1rem;
    
                > .item{
                    
                    display: grid;
                    grid-template-columns: auto 1fr;
                    gap: 0.7em;
                    font-size: 1.05rem;
                    
                    > svg{
                        margin-top: 0.3em;
                        fill: colors.$theme-green;
                        @include setProps(width height, 1em);
                    }
                    > .title{
                        color: gray(0.3);
                        @include break-long-words;
                    }
                }
            }
    
            @include respondToSmallMaxSize{
                grid-template-columns: 1fr 1fr;
                grid-template-rows: auto auto;
                gap: unset;
                row-gap: 1.5rem;
                column-gap: 1.3rem;
                margin-top: 5rem; 
                align-items: start;

                .TitleSubtitleContainer{
                    grid-column: 1/3;
                    justify-self: center;
                    max-width: unset;
                }
                > .column > .item{
                    font-size: 0.95rem;
                }

            }
        }
    }


}
