
@use 'sass:color';
@use 'helpers/colors';
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

*{
    margin: 0;
    padding: 0.02px;
    -webkit-text-size-adjust: none;
}

body {
	font-family: Poppins;
	color: colors.$off-black-title;
}

a{
    color: inherit;
    text-decoration: none;
}

button{
    background-color: transparent;
    border: none;
    color: inherit;
    font-size: inherit;
    cursor: pointer;
}


button:focus, input:focus, textarea:focus, select:focus{
    outline: none;
}   

textarea, input, select{
    border: none;
    font-family: inherit;
    background-color: transparent;
    font-size: 1rem;
}

textarea, input{
    min-width: 0;
    -webkit-appearance: none;   
}



textarea{
    resize: none;
}

select{
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: transparent;
}

$text-selection-color: color.adjust(colors.$theme-green, $alpha: -0.6);

::selection {
    background: $text-selection-color; /* WebKit/Blink Browsers */
}

::-moz-selection {
    background: $text-selection-color; /* Gecko Browsers */
}




