
@use 'helpers/general' as *;
@use 'helpers/colors';
@use 'sass:color';

.VeggieFoodSection{

    position: relative;
    overflow: visible;
    text-align: center;

    @mixin respondToScrollableWidth {
        @media(max-width: 59.38rem){
            @content;
        }
    }

    > .see-todays-menu{
        
        @include setProps(margin-left margin-right, auto);

        margin-top: 1.3rem;
        padding: 1.1rem;
        @include setProps(padding-top padding-bottom, 0.8rem);

        border: 0.15rem solid colors.$theme-green;
        border-radius: 1rem;
        display: inline-grid;
        grid-auto-flow: column;
        gap: 0.7rem;
        justify-self: center;
        align-items: center;
        
        > .text{
            color: colors.$theme-green;
            font-size: 0.95rem;
            font-weight: 500;
        }
        
        @include bounceOnHover;
    }


    > .info-boxes{

        text-align: left;
        margin-top: 3rem;

        @include setProps(margin-left margin-right, calc(var(--screen-side-insets) * -1));
        
        display: grid;

        @include setCustomScrollBarStyle($color: gray(0.85));
        @include respondToScrollableWidth{
            overflow-x: scroll;
            margin-top: 1rem;
        }

        > .content-holder{

            --item-width: 22rem;

            gap: 2rem;
            display: inline-grid;
            grid-template-columns: repeat(auto-fill, var(--item-width));
            justify-content: center;
            @include setProps(padding-left padding-right, var(--screen-side-insets));


            @mixin respondToSmallCardsWidth{
                @media (max-width: 77rem){
                    @content;
                }
            }

            @include respondToSmallCardsWidth{
                --item-width: max(min(20rem, 100vw - 5rem), 12rem);
                gap: 1.25rem;
            }
            
            @include respondToScrollableWidth{
                grid-template-columns: unset;
                justify-content: unset;
                grid-auto-flow: column;
                grid-auto-columns: var(--item-width);
                @include setProps(padding-top padding-bottom, 2.2rem);
            }

            > .info-box{
                
                display: grid;
                gap: 1rem;
                align-content: start;
                background-color: white;
                border-radius: 1.2rem;
                overflow: hidden;
                padding: 1.4rem;

                @include respondToSmallCardsWidth{
                    gap: 0.7rem;
                    padding: 1.1rem;
                }

                @mixin set-box-shadow($color){
                    box-shadow: 0 0 2rem $color;
                }

                @include set-box-shadow(rgba(black, 0.1));
    
                transition-duration: 0.2s;
                transition-timing-function: ease-out;
                transition-property: transform, box-shadow;

                @media(hover: hover){
                    &:hover{
                        transform: scale(1.05);
                        @include set-box-shadow(rgba(black, 0.2));
                    }
                }

                > svg{
                    @include setProps(width height, 2.5rem);
                    @include respondToSmallCardsWidth{
                        @include setProps(width height, 2rem);
                    }
                }
                
                > .title{
                    font-weight: bold;
                    font-size: 1.1rem;
                    // color: colors.$theme-green;
                    @include respondToSmallCardsWidth{
                        font-size: 0.95rem;
                    }
                }

                > .description{
                    font-size: 0.85rem;
                    color: colors.$off-black-subtitle;
                }
            }
        }        
    }
}

