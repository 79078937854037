@use 'helpers/general' as *;

.HeaderFooterContainer{

    max-width: var(--max-screen-width);
    
    --screen-side-insets: 2.5rem;
    --max-screen-width: 78rem;

    @include setProps(padding-left padding-right, var(--screen-side-insets));
    padding-top: var(--nav-bar-height);
    @include setProps(margin-left margin-right, auto);

    @include respondToSmallMaxSize{
        --screen-side-insets: 1.5rem;  
    }

}