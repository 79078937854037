
@use 'helpers/colors';
@use 'helpers/general' as *;
@use 'sass:color';


.AppAndMapSection{
    
    @include setProps(padding-left padding-right, 2rem);

    background-color: colors.$gray-background;
    border-radius: 1.5rem;

    @include setProps(padding-top padding-bottom, 3rem);

    display: grid;
    gap: 4rem;    
    justify-items: center;

    @include respondToSmallMaxSize{
        gap: 3rem;
        @include setProps(padding-left padding-right, 1.6rem);
    }


    .text-container{
        display: grid;
        gap: 1rem;
        font-size: 1rem;

        > .title{
            line-height: 1.3;
            font-weight: bold;
            font-size: 2.1rem;
            @include respondToSmallMaxSize{
                font-size: 1.6rem;
            }
        }

        > .description{
            color: gray(0.6);
        }
    }


    > .app-section{
        
        max-width: 56rem;

        display: grid;
        grid-template-columns: auto 1fr;
        gap: 4rem;
        align-items: center;

        @include respondToMediumMaxSize{
            gap: 3rem;
            @include respondToSmallMaxSize{
                grid-template-columns: unset;
                gap: 2rem;
            }
        }

        > .left-side{
            > div{
                width: 24rem;
                @include setProps(margin-left margin-right, auto);

                @include respondToMediumMaxSize{
                    width: 17rem;
                    @include respondToSmallMaxSize{
                        width: unset;
                        max-width: 20rem;
                    }
                }

                > div{
                    padding-top: 130%;
                    position: relative;

                    > .phone-images-holder{
                        position: absolute;
                        @include setProps(left right top bottom, 0);
                        
                        > img{
                            filter: drop-shadow(0 0 1.5rem rgba(black, 0.25));
                            will-change: filter; // without this, the images flicker on web safari and there are horizontal scrolling issues on ios safari
                            display: block;
                            position: absolute;
                            top: 0;
                            height: 100%;
                            &.image-1{
                                left: 0;
                            }
                            &.image-2{
                                right: 0;
                                z-index: 1;
                            }
                        }
                    }
                }
            }
        }
    
        > .right-side{
            > .app-store-buttons{

                $items-gap: 1rem;

                margin-left: -$items-gap;
                margin-top: -$items-gap + 0.5rem;
                display: flex;
                flex-wrap: wrap;
                
                img{
                    background-color: transparent;
                    display: block;
                    height: 2.8125rem;

                    @include respondToSmallMaxSize{
                        height: 2.5rem;
                    }
                }

                > *{
                    margin-left: $items-gap;
                    margin-top: $items-gap;
                }

                
            }

            > .web-app-button{
                margin-top: 0.3rem;
                display: block;
                justify-self: start;
                border: 0.17rem solid colors.$off-black-title;
                border-radius: 0.7rem;
                padding: 0.7rem;
                @include setProps(padding-left padding-right, 0.9rem);
                display: grid;
                align-items: center;
                font-weight: 500;
                @include bounceOnHover;
            }
        }
    }

    > .map-section{

        max-width: 60rem;
        display: grid;
        gap: 2rem;
        grid-template-columns: 1fr 1.6fr;
        align-items: center;

        > .BetterLivingLocationMap{
            border-radius: 1.3rem;
            overflow: hidden;
            z-index: 1;
            height: 23rem;
            width: 100%;
        }

        @include respondToSmallMaxSize{
            grid-template-columns: unset;
            > .BetterLivingLocationMap{
                grid-row: 1/2;
                height: 20rem;
            }

            
        }
    }

}


