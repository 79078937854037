@use 'helpers/colors';
@use 'helpers/general' as *;

.HomePage .SectionTitleView{
    
    display: grid;
    justify-content: center;
    text-align: center;
    justify-items: center;

    > .subtitle{
        text-transform: uppercase;
        font-size: 0.91rem;
        font-weight: 600;
        color: colors.$theme-green;
    }

    > .title{
        font-weight: bold;
        font-size: 2.2rem;
        margin-top: 0.5rem;
    }

    > .description{
        line-height: 1.7;
        color: gray(0.6);
        max-width: 50rem;
        font-weight: 400;
        margin-top: 1.3rem;
        font-size: 1rem;
    }

    @include respondToSmallMaxSize{
        > .subtitle{
            font-size: 0.8rem;
        }
        > .title{
            font-size: 1.7rem;
        }

        > .description{
            font-size: 0.91rem;
        }
    }
    
}
