
@use 'helpers/general' as *;
@use 'helpers/colors';
@use 'sass:color';

$nav-bar-collapse-max-width: 63rem;

:root{
    --nav-bar-collapse-max-width: #{$nav-bar-collapse-max-width};
    --nav-bar-height: 6rem;
    
    @include respondToSmallMaxSize{
        --nav-bar-height: 4rem;
    }
}

.HeaderFooterContainer{

    .NavBar{

        @mixin get-box-shadow($show-shadow){
            $shadow-color: color.adjust(black, $alpha: if($show-shadow, -0.9, -1));
            box-shadow: 0 0 0.8rem $shadow-color;
        }

        position: fixed;
        @include setProps(left right top, 0);
        background-color: color.adjust(white, $alpha: -0.2);
        backdrop-filter: blur(10px);
        z-index: 100;
        @include get-box-shadow($show-shadow: false);
        transition: box-shadow 0.2s;

        &.scrolled-up{
            @include get-box-shadow($show-shadow: true);
        }

        
    }

    .NavBar > .content{

        @include setProps(padding-left padding-right, var(--screen-side-insets));
        @include setProps(margin-left margin-right, auto);
        max-width: var(--max-screen-width);

        display: grid;
        justify-content: space-between;
        grid-auto-flow: column;
        align-items: center;


        height: var(--nav-bar-height);

        > .logo-and-title-holder{
        
            display: grid;
            grid-auto-flow: column;
            align-items: center;
            gap: 1rem;

            .better-living-title{
                font: {
                    weight: 500;
                    size: 1.1rem;
                }
                color: colors.$off-black-title;
                display: grid;
                gap: 0.1rem;
            }

            .brocoli-image{
                @include setProps(height width, 3rem);
                object-fit: contain;
                @include respondToSmallMaxSize{
                    @include setProps(height width, 2.5rem);
                }
            }
        }

        > .nav-link-container{
            display: grid;
            grid-auto-flow: column;
            gap: 1rem;
            align-items: center;
            
            .NavLink{
                font-size: 1rem;
                @include greenBGOnHover;
                @include setProps(padding-left padding-right, 1rem);
                @include setProps(padding-top padding-bottom, 0.6rem);

                &.selected{
                    color: colors.$theme-green;
                }
            }
        }

        > .menu-svg{
            fill: colors.$off-black-title;
            @include setProps(height width, 1.5rem);
            display: none;
            @include bounceOnHover;
        }


        > .web-app-button{
            padding: 0.9rem;
            @include setProps(padding-left padding-right, 1.2rem);
            align-self: center;
            background-color: colors.$theme-green;
            color: white;
            border-radius: 0.8rem;
            font-size: 0.95rem;
            font-weight: 500;
            @include bounceOnHover;
        }

        @media (max-width: $nav-bar-collapse-max-width){

            > .nav-link-container{
                display: none;
            }
            > .menu-svg{
                display: unset;
            }
            
            > .web-app-button{
                display: none;
            }
        }
        
    }

}

